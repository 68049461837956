import React from 'react';
import { Github, Linkedin, BookOpen, Moon, Sun } from 'lucide-react';

const CodeStylePortfolio = () => {
  const [theme, setTheme] = React.useState('dark');

  React.useEffect(() => {
    // Add dark class to HTML element on initial load
    document.documentElement.classList.add('dark');
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.classList.toggle('dark');
  };

  const LineNumber = ({ number }) => (
    <span className="text-slate-400 dark:text-slate-600 text-sm select-none min-w-[3rem] inline-block text-right pr-4 border-r border-slate-200 dark:border-slate-700 shrink-0">
      {number}
    </span>
  );

  const CodeLine = ({ children, lineNumber }) => (
    <div className="group hover:bg-slate-100 dark:hover:bg-slate-800/50 text-slate-900 dark:text-slate-100 flex">
      <LineNumber number={lineNumber} />
      <div className="pl-4 flex-1 whitespace-pre-wrap break-words">{children}</div>
    </div>
  );

  const CodeBlock = ({ title, children, startLine = 1 }) => (
    <div className="font-mono text-sm mb-6">
      <div className="bg-slate-100 dark:bg-slate-800 px-4 py-2 text-slate-600 dark:text-slate-400 border-b border-slate-200 dark:border-slate-700">
        {title}
      </div>
      <div className="p-4 bg-white dark:bg-slate-900 border border-slate-200 dark:border-slate-700 rounded-b-lg text-slate-900 dark:text-slate-100">
        {React.Children.map(children, (child, index) => 
          React.cloneElement(child, { lineNumber: startLine + index })
        )}
      </div>
    </div>
  );

  // Rest of the components remain the same...
  const Keyword = ({ children }) => (
    <span className="text-purple-600 dark:text-purple-400">{children}</span>
  );

  const MultiLineString = ({ children }) => (
    <span className="text-green-600 dark:text-green-400">
      "{children[0]}" +{'\n'}
      <span className="pl-16">"</span>{children[1]}"
    </span>
  );

  const String = ({ children, href }) => (
    href ? (
      <a 
        href={href} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-green-600 dark:text-green-400 hover:underline cursor-pointer"
      >
        "{children}"
      </a>
    ) : (
      <span className="text-green-600 dark:text-green-400">"{children}"</span>
    )
  );

  const Function = ({ children }) => (
    <span className="text-blue-600 dark:text-blue-400">{children}</span>
  );

  const Comment = ({ children }) => (
    <span className="text-slate-400 dark:text-slate-600">// {children}</span>
  );

  return (
    <div className={`min-h-screen bg-slate-50 dark:bg-slate-900 p-6 transition-colors duration-200 ${theme}`}>
      <div className="max-w-5xl mx-auto">
        {/* Header with Image and Navigation */}
        <div className="flex justify-between items-start mb-8">
          {/* Profile Image */}
          <div className="w-32 h-32 rounded-full overflow-hidden border-2 border-slate-200 dark:border-slate-700">
            <img 
              src="/me.png" 
              alt="Andy Sawyer" 
              className="w-full h-full object-cover"
            />
          </div>

          {/* Icon Navigation */}
          <div className="flex gap-2">
            <button 
              className="p-2 rounded-full hover:bg-slate-200 dark:hover:bg-slate-800 text-slate-800 dark:text-slate-200"
              onClick={() => window.open('https://github.com/nydasco/', '_blank')}
              aria-label="GitHub Profile"
            >
              <Github className="h-5 w-5" />
            </button>
            <button 
              className="p-2 rounded-full hover:bg-slate-200 dark:hover:bg-slate-800 text-slate-800 dark:text-slate-200"
              onClick={() => window.open('https://www.linkedin.com/in/andrewdsawyer/', '_blank')}
              aria-label="LinkedIn Profile"
            >
              <Linkedin className="h-5 w-5" />
            </button>
            <button 
              className="p-2 rounded-full hover:bg-slate-200 dark:hover:bg-slate-800 text-slate-800 dark:text-slate-200"
              onClick={() => window.open('https://medium.com/@nydas', '_blank')}
              aria-label="Medium Blog"
            >
              <BookOpen className="h-5 w-5" />
            </button>
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full hover:bg-slate-200 dark:hover:bg-slate-800"
              aria-label="Toggle theme"
            >
              {theme === 'light' ? (
                <Sun className="h-5 w-5 text-slate-800" />
              ) : (
                <Moon className="h-5 w-5 text-slate-200" />
              )}
            </button>
          </div>
        </div>

        {/* Title */}
        <h1 className="text-3xl font-bold text-slate-900 dark:text-slate-100 mb-8 font-mono">
          Andy Sawyer Profile
        </h1>

        {/* Header as import statements */}
        <CodeBlock title="profile.ts" startLine={1}>
          <CodeLine><Comment>// Professional Profile</Comment></CodeLine>
          <CodeLine><Keyword>const</Keyword> overview = {`{`}</CodeLine>
          <CodeLine>  name: <String>Andy Sawyer</String>,</CodeLine>
          <CodeLine>  role: <String>Strategy Leader & Advisor - Data, AI & Automation</String>,</CodeLine>
          <CodeLine>  links: {`{`}</CodeLine>
          <CodeLine>    github: <String href="https://github.com/nydasco">https://github.com/nydasco</String>,</CodeLine>
          <CodeLine>    linkedin: <String href="https://www.linkedin.com/in/andrewdsawyer">https://www.linkedin.com/in/andrewdsawyer</String>,</CodeLine>
          <CodeLine>    medium: <String href="https://medium.com/@nydas">https://medium.com/@nydas</String></CodeLine>
          <CodeLine>  {`}`}</CodeLine>
          <CodeLine>{`}`};</CodeLine>
        </CodeBlock>

        {/* Experience */}
        <CodeBlock title="experience.ts" startLine={1}>
          <CodeLine><Comment>// Professional Overview</Comment></CodeLine>
          <CodeLine><Keyword>class</Keyword> <Function>ProfessionalExperience</Function> {`{`}</CodeLine>
          <CodeLine>  <Function>getOverview</Function>() {`{`}</CodeLine>
          <CodeLine>    <Keyword>return</Keyword> <String>Extensive experience in data leadership roles, spanning NYSE-listed corporations, private-equity backed multinationals, and bootstrapped scale-ups</String>;</CodeLine>
          <CodeLine>  {`}`}</CodeLine>
          <CodeLine></CodeLine>
          <CodeLine>  <Function>getAchievements</Function>() {`{`}</CodeLine>
          <CodeLine>    <Keyword>return</Keyword> [</CodeLine>
          <CodeLine>      <String>Led teams of 20+ members</String>,</CodeLine>
          <CodeLine>      <String>Managed budgets over $5M</String>,</CodeLine>
          <CodeLine>      <String>Established robust data governance practices</String>,</CodeLine>
          <CodeLine>      <String>Deployed six greenfield data warehouses</String>,</CodeLine>
          <CodeLine>      <String>Delivered three successful data strategies</String></CodeLine>
          <CodeLine>    ];</CodeLine>
          <CodeLine>  {`}`}</CodeLine>
          <CodeLine>{`}`}</CodeLine>
        </CodeBlock>

        {/* Skills */}
        <CodeBlock title="skills.ts" startLine={1}>
          <CodeLine><Comment>// Technical Proficiencies</Comment></CodeLine>
          <CodeLine><Keyword>const</Keyword> skills = {`{`}</CodeLine>
          <CodeLine>  currentFocus: [</CodeLine>
          <CodeLine>    <String>Data Governance Frameworks</String>,</CodeLine>
          <CodeLine>    <String>Team Development</String>,</CodeLine>
          <CodeLine>    <String>Data Strategy</String></CodeLine>
          <CodeLine>  ],</CodeLine>
          <CodeLine>  coreSkills: [</CodeLine>
          <CodeLine>    <String>Strategic Leadership</String>,</CodeLine>
          <CodeLine>    <String>Data Engineering</String>,</CodeLine>
          <CodeLine>    <String>Data Analytics</String></CodeLine>
          <CodeLine>  ],</CodeLine>
          <CodeLine>  techStack: [</CodeLine>
          <CodeLine>    <String>Snowflake</String>,</CodeLine>
          <CodeLine>    <String>dbt</String>,</CodeLine>
          <CodeLine>    <String>AWS</String>,</CodeLine>
          <CodeLine>    <String>Python</String>,</CodeLine>
          <CodeLine>    <String>TypeScript</String>,</CodeLine>
          <CodeLine>    <String>Docker</String>,</CodeLine>
          <CodeLine>    <String>FiveTran</String></CodeLine>
          <CodeLine>  ]</CodeLine>
          <CodeLine>{`}`};</CodeLine>
        </CodeBlock>

        {/* Qualifications */}
        <CodeBlock title="qualifications.ts" startLine={1}>
          <CodeLine><Comment>// Qualifications and Certifications</Comment></CodeLine>
          <CodeLine><Keyword>const</Keyword> qualificationsAndCertifications = {`{`}</CodeLine>
          <CodeLine>  qualifications: [</CodeLine>
          <CodeLine>    <String>Graduate Certificate, Data Science - RMIT (2020)</String>,</CodeLine>
          <CodeLine>    <String>Graduate Certificate, Professional Accounting - CQU (2014)</String>,</CodeLine>
          <CodeLine>    <String>Bachelor of Business Administration - SCU (2012)</String></CodeLine>
          <CodeLine>  ],</CodeLine>
          <CodeLine>  certifications: [</CodeLine>
          <CodeLine>    <String>SAFe (2024)</String>,</CodeLine>
          <CodeLine>    <String>CPA Australia (2018)</String>,</CodeLine>
          <CodeLine>    <String>Certified Scrum Master (2017)</String>,</CodeLine>
          <CodeLine>    <String>PRINCE2 (2012)</String></CodeLine>
          <CodeLine>  ]</CodeLine>
          <CodeLine>{`}`};</CodeLine>
        </CodeBlock>

        {/* Footer */}
        <div className="text-center py-8 text-slate-500 dark:text-slate-400 font-mono text-sm">
          <p>© {new Date().getFullYear()} Andy Sawyer • nydas.co</p>
        </div>
      </div>
    </div>
  );
};

export default CodeStylePortfolio;